<template>
  <v-dialog
    v-if="showMe"
    v-model="dialog"
    scrollable
    :persistent="!!noSetupIds.length"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-switch
        label="Online Check-in"
        @click.stop="dialog = true"
        v-model="isActive"
        readonly
        v-on="on"
        color="success"
        hide-details
      ></v-switch>
    </template>
    <v-card>
      <!-- Toolbar -->
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>
            <v-icon color="color2Text" class="mr-3">fas fa-clipboard-list-check</v-icon>
            Online Check-in Setup
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2Text"
            text icon
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <loading-bar :value="loading"></loading-bar>
      <!-- Body -->
        <v-card-text class="pa-2">
          <div class="text-center" v-if="false">
            <v-chip color="error white--text">
              <v-avatar left>
                <v-icon small>far fa-exclamation-triangle</v-icon>
              </v-avatar>
              COVID-19 alert While Covid-19 presents a risk, we suggest all events use online check-in to promote social distancing.
            </v-chip>
          </div>
          Please review the settings below and click save.
          <v-btn
            color="color3"
            x-small text
            @click.stop="more = !more"
          >
            {{more ? 'less' : 'more'}} info
          </v-btn>
          <v-expand-transition>
            <div v-if="more" class="mt-2">
              We will send a check-in email to each player at the specified division check-in start time.
              The email will contain a link that will allow them to check in.
              You can choose to either have 1 person check in the team or have each player check in separately.
            </div>
          </v-expand-transition>
      <!-- Windows -->
          <v-card v-for="(w, i) in checkinWindows" :key="`div-${w.division.id}-${i}`" class="mt-2">
            <v-card-text :class="`pa-2 pb-0 ${w.window.complete ? 'grey lighten-3' : 'color2 color2Text--text'} d-flex justify-space-between`">
              <span>{{w.division.name}}{{w.window.complete ? ' - emails have been sent' : ''}}</span>
              <v-btn
                color="white"
                x-small
                text
                icon
                v-if="user && user.id === 1"
                @click.stop="dupe(w, i)"
              >
                <v-icon>fas fa-copy</v-icon>
              </v-btn>
            </v-card-text>

              <v-skeleton-loader
                :loading="loading"
                type="card-heading"
              >
                <v-card-text class="pa-2">
                  <v-select
                    :items="['Team Check-in','Player Check-in','No Online Check-in']"
                    v-model="w.window.type"
                    label="Use Online Check-in"
                    color="color3"
                    item-color="color3"
                    :disabled="w.window.complete"
                    hide-details
                  ></v-select>
                  <v-expand-transition>
                    <div v-if="w.window.active">
                      <date-time-picker
                        :datetime.sync="w.window.dtStart"
                        label="Start"
                        :disabled="w.window.complete"
                      ></date-time-picker>
                      <date-time-picker
                        :datetime.sync="w.window.dtEnd"
                        label="End"
                        :disabled="w.window.complete"
                      ></date-time-picker>
                      <div v-if="i === 0" class="text-end" @click.stop="copyDown">
                        <v-btn color="color3" text x-small>copy down</v-btn>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-skeleton-loader>
          </v-card>
        </v-card-text>
      <!-- Buttons -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            fab small
            :disabled="loading || !dirty"
            :loading="saving "
            @click.stop="save"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-card-actions>
      <!-- Buttons -->
        <v-snackbar
          v-model="snack"
          absolute
        >
          Check-in setting saved!
          <v-btn text color="color3" @click.native="snack = false">Close</v-btn>
        </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import OnlineCheckinWindow from '@/classes/OnlineCheckinWindow'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import { firstBy } from 'thenby'
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'

export default {
  props: ['tournament'],
  data () {
    return {
      dialog: false,
      loading: false,
      checkinWindows: [],
      dbWindows: [],
      saving: false,
      more: false,
      snack: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    divisionIds () {
      return this.tournament.publicDivisions.filter(f => !f.isCoaches).map(m => m.id)
    },
    setupIds () {
      return {
        active: this.tournament.onlineCheckin,
        inactive: this.tournament.noOnlineCheckin
      }
    },
    noSetupIds () {
      return this.divisionIds.filter(f => !this.setupIds.active.includes(f) && !this.setupIds.inactive.includes(f))
    },
    dto () {
      return this.checkinWindows && this.checkinWindows.map(m => m.window.dto)
    },
    dirty () {
      if (!this.checkinWindows || !this.dto.length) return false

      const og = this.dbWindows.map(m => m.dto)
      og.sort(firstBy('divisionId'))
      const dto = this.dto
      dto.sort(firstBy('divisionId'))

      return JSON.stringify(og) !== JSON.stringify(dto)
    },
    isActive () {
      return !!this.setupIds.active.length
    },
    showMe () {
      return (this.tournament && this.tournament.isEditable && !this.tournament.isPast) || (this.user && this.user.vbl)
    },
    isEditable () {
      return this.tournament && this.tournament.isEditable
    },
    isPast () {
      return this.tournament && this.tournament.isPast
    },
    published () {
      return this.tournament && this.tournament.isPublic
    }
  },
  methods: {
    dupe (window, i) {
      const d = window.division
      this.checkinWindows.splice(i + 1, 0, {
        division: d,
        window: new OnlineCheckinWindow({
          divisionId: d.id,
          dtStart: d.days[0].dtCheckin,
          dtEnd: d.days[0].dtStart,
          active: true
        })
      })
    },
    map () {
      this.checkinWindows = flatten(
        this.tournament.publicDivisions.filter(f => !f.isCoaches).map(d => {
          const r = this.dbWindows.filter(f => f.divisionId === d.id).map(w => {
            return {
              division: d,
              window: new OnlineCheckinWindow(w.dto)
            }
          })
          if (!r.length) {
            r.push({
              division: d,
              window: new OnlineCheckinWindow({
                divisionId: d.id,
                dtStart: d.days[0].dtCheckin,
                dtEnd: d.days[0].dtStart,
                active: true
              })
            })
          }
          return r
        })
      )
    },
    copyDown () {
      const a = this.checkinWindows[0].window.dtStart
      const b = this.checkinWindows[0].window.dtEnd
      this.checkinWindows.forEach((m, i) => {
        if (m.window.active) {
          m.window.dtStart = a
          m.window.dtEnd = b
        }
      })
    },
    load () {
      this.loading = true
      this.$VBL.tournament.checkin.getWindows(this.tournament.id)
        .then(r => {
          this.init(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    save () {
      this.saving = true
      const pop = false // !!this.noSetupIds.length
      this.$VBL.tournament.checkin.postWindows(this.tournament.id, this.dto)
        .then(r => {
          this.init(r.data)
          this.snack = true
          if (pop) {
            this.dialog = false
          }
        })
        .catch(e => console.log(e))
        .finally(() => { this.saving = false })
    },
    init (data) {
      this.dbWindows = data.map(m => new OnlineCheckinWindow(m))
      this.map()
    }
  },
  watch: {
    dialog: function (v) {
      v && this.load()
    }
  },
  components: {
    DateTimePicker
  },
  mounted () {
    this.map()
    // if (this.noSetupIds.length && !this.tournament.isPast) {
    //   this.dialog = true
    // }
  }
}
</script>
