<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- LOCK -->
    <v-alert type="warning" prominent :value="tournament.readyToComplete && !tournament.locked" transition="scale-transition">
      <v-row align="center">
        <v-col class="grow">
          <strong class="black--text">All your divisions are complete. Lock the tournament?</strong>
        </v-col>
        <v-col class="shrink">
          <v-btn
            @click.stop="toggleLock(true)"
            :loading="patching"
          >lock</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar tabs color="color1 color1Text--text">
            <v-toolbar-title>{{tournament.eventType}} Manager</v-toolbar-title>
            <v-spacer></v-spacer>
            <quick-add-dialog
              :tournament="tournament"
              fab
              v-if="!tournament.locked"
            ></quick-add-dialog>
            <lock-toggle v-if="tournament.locked" :warn="true" @unlock-click="toggleLock(false)" title="Unlock The Tournament?">
              <div class="title mb-3">Results have are been submitted.</div>
              If you change any finishes now you must re-finish the tournament to update and submit the results.
            </lock-toggle>
          </v-toolbar>
          <v-container fluid class="pa-0">
          <v-tabs background-color="color2" slider-color="color3" v-model="currentTab" touchless dark show-arrows>
            <v-tab href="#settings" ripple class="color2Text--text">Settings</v-tab>
            <v-tab href="#custom-fields" ripple class="color2Text--text">Custom Fields</v-tab>
            <v-tab href="#registrations" ripple class="color2Text--text">Registrations</v-tab>
            <v-tab href="#leads" ripple class="color2Text--text">Leads</v-tab>
            <v-tab href="#upload" ripple class="color2Text--text" v-if="showImport">Import</v-tab>
            <v-tab href="#results" ripple class="color2Text--text" v-if="tournament.isTournament">Results</v-tab>
            <v-tab href="#emailer" ripple class="color2Text--text">Email</v-tab>
            <v-tab href="#agreements" ripple class="color2Text--text">Agreements</v-tab>
            <v-tab href="#checklists" ripple class="color2Text--text" v-if="showChecklist">Checklists</v-tab>
            <v-tab href="#jprops" ripple class="color2Text--text" v-if="user && user.vbl">Json Props</v-tab>
            <v-tab href="#fields" ripple class="color2Text--text" v-if="fields">Field Manager</v-tab>
            <v-tab href="#ratings" ripple class="color2Text--text" v-if="tournament.hasJuniors">Ratings Results</v-tab>

            <v-tab
              v-for="p in bidPrograms"
              :key="`bids-t-${p.id}`"
              :href="`#bids${p.id}`"
              ripple
              class="color2Text--text"
            >Bids</v-tab>
            <v-tab href="#club-bids" ripple class="color2Text--text" v-if="tournament.is('club-bids')">Bids</v-tab>
            <v-tab href="#reports" ripple class="color2Text--text" v-if="tournament.props.includes('reports')">Reports</v-tab>
            <v-tab href="#pics" ripple class="color2Text--text" v-if="false">Photos</v-tab>
            <v-tab href="#team" ripple class="color2Text--text" v-if="showMerge">Team Maker</v-tab>

            <!-- TOURNAMENT EDITOR -->
            <v-tab-item value="settings" key="settings">
              <loading-bar :value="patching"></loading-bar>
              <v-container fluid class="py-0">
                <v-row no-gutters justify="end" style="min-height:36px">
                  <score-board v-if="tournament.isNcaa && tournament.duals && tournament.duals.length"></score-board>
                  <game-reminders
                    v-if="tournament.isLeague"
                  ></game-reminders>
                  <v-btn color="color3" text x-small v-if="tournament.isLeague && false" @click.stop="regen">Re-gen</v-btn>
                  <pdf-print
                    :btnText="'Print QR Code Package'"
                    :pages="tournament.qrCodePackage"
                    :name="`${tournament.name} QR Codes`"
                    :tournament="tournament"
                  ></pdf-print>
                  <password-protect v-if="!tournament.locked"></password-protect>
                </v-row>
                <v-row dense class="align-baseline">
                  <v-switch label="Published"
                    class="shrink  mr-4"
                    v-model="tournament.isPublic"
                    color="success"
                    @click.stop="onPublish"
                    :disabled="tournament.locked"
                    readonly
                    :loading="loading.includes('publish')"
                    hide-details>
                  </v-switch>
                  <v-switch
                    v-if="tournament.isCompetitive"
                    class="shrink mr-4"
                    readonly
                    label="Public Scoring"
                    v-model="tournament.publicScoring"
                    color="success"
                    @click.stop="patch('publicScoring', !tournament.publicScoring)"
                    :loading="loading.includes('publicScoring')"
                    :disabled="tournament.locked"
                    hide-details
                  ></v-switch>
                  <online-checkin-setup :tournament="tournament" v-if="tournament && !tournament.isLeague"></online-checkin-setup>
                  <v-switch
                    v-if="!tournament.privateOnly || user && user.vbl"
                    class="shrink ml-4"
                    readonly
                    label="Private"
                    v-model="tournament.organizationListOnly"
                    color="success"
                    @click.stop="patch('organizationListOnly', !tournament.organizationListOnly)"
                    :loading="loading.includes('organizationListOnly')"
                    :disabled="tournament.locked"
                    hide-details
                  ></v-switch>
                  <v-switch
                      v-if="tournament.isCompetitive"
                      class="shrink ml-4"
                      readonly
                      label="Streaming"
                      v-model="tournament.isStreaming"
                      color="success"
                      @click.stop="patchProps('streaming', !tournament.isStreaming)"
                      hide-details
                      :loading="loading.includes('props')"
                    ></v-switch>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="color3 color3Text--text"
                    text x-small
                    v-if="user && user.vbl"
                    @click.stop="showAdmin = !showAdmin"
                  >
                    {{showAdmin ? 'Hide ' : 'Show '}}Vbl Admin
                  </v-btn>
                </v-row>
                <v-expand-transition>
                  <v-row dense v-if="user && user.vbl && showAdmin">
                    <v-switch
                      class="shrink mr-4"
                      readonly
                      label="National Event"
                      v-model="tournament.national"
                      color="success"
                      @click.stop="patch('national', !tournament.national)"
                      hide-details
                      :loading="loading.includes('national')"
                    ></v-switch>
                    <v-switch
                      class="shrink mr-4"
                      readonly
                      label="Showcase"
                      v-model="tournament.isShowcase"
                      color="success"
                      @click.stop="patchProps('isShowcase', !tournament.isShowcase)"
                      hide-details
                      :loading="loading.includes('props')"
                    ></v-switch>
                    <v-switch
                      class="shrink mr-4"
                      readonly
                      label="Public Can Nav"
                      v-model="tournament.publicCanNav"
                      color="success"
                      @click.stop="patch('publicCanNav', !tournament.publicCanNav)"
                      hide-details
                      :loading="loading.includes('publicCanNav')"
                    ></v-switch>
                  </v-row>
                </v-expand-transition>
              </v-container>
              <tournament-editor
                :tournamentIn="tournament"
                :btnOffset="60"
                ref="editor"
              ></tournament-editor>

            <!-- BOTTOM BUTTONS -->
              <v-container>
                <v-row dense>
                  <v-col class="text-right" cols="12" >
                    <!-- LOGO -->
                    <logo-wizard></logo-wizard>
                    <!-- DELETE -->
                    <delete-cancel-dialog :tournament="tournament" mode="Delete"></delete-cancel-dialog>
                    <!-- DELAYED PAYMENT -->
                    <delayed-payment v-if="false"></delayed-payment>
                  </v-col>
                </v-row>
              </v-container>
              <!-- DOUBLE POINTS -->
              <double-points v-if="userIsSB"></double-points>
            </v-tab-item>

            <!-- Custom Fields -->
            <v-tab-item value="custom-fields" key="custom-fields">
              <custom-fields
                :tournament="tournament"
                :active="currentTab === 'custom-fields'"
              ></custom-fields>
            </v-tab-item>

            <!-- Registrations -->
            <v-tab-item value="registrations" key="registrations">
              <registration-viewer
                :tournament="tournament"
                :active="currentTab === 'registrations'"
                @upload-click="currentTab='upload'"
                @email-click="onEmailClick"
                @email-sent="currentTab='emailer'"
              ></registration-viewer>
            </v-tab-item>

            <!-- Leads -->
            <v-tab-item value="leads" key="leads">
              <lead-viewer
                :tournament="tournament"
                :active="currentTab === 'leads'"
              ></lead-viewer>
            </v-tab-item>

            <!-- REGISTRATION UPLOADER -->
            <v-tab-item value="upload" key="upload" v-if="showImport">
              <team-importer
                :tournament="tournament"
                :active="currentTab === 'upload'"
                @complete="currentTab = 'registrations'"
              ></team-importer>
            </v-tab-item>

            <!-- RESULTS EDITOR -->
            <v-tab-item value="results" key="results" v-if="tournament.isTournament">
              <results-editor
                v-if="true"
                :tournament="tournament"
                :color="'color3 color3Text--text'"
              ></results-editor>
            </v-tab-item>

            <!-- EMAILER -->
            <v-tab-item value="emailer" key="emailer">
              <tournament-email-box
                :active="currentTab === 'emailer'"
                :teamIds="teamIds"
                @clear="teamIds = null"
              ></tournament-email-box>
            </v-tab-item>

            <!-- BIDS -->
            <v-tab-item
              v-for="p in bidPrograms"
              :key="`bids-c-${p.id}`"
              :value="`bids${p.id}`"
              class="pa-4"
            >
              <bid-manager :active="currentTab === `bids${p.id}`" :program="p" :tournamentId="tournament.id"></bid-manager>
            </v-tab-item>
            <!-- BIDS -->
            <v-tab-item
              key="club-bids"
              value="club-bids"
              class="pa-4"
            >
              <club-bids :active="currentTab === 'club-bids'" :tournamentId="tournament.id"></club-bids>
            </v-tab-item>

            <!-- REPORTS -->
            <v-tab-item value="reports" key="reports" v-if="tournament.props.includes('reports')">
              <report-generator :active="currentTab === 'reports'"></report-generator>
            </v-tab-item>

            <!-- PICS -->
            <v-tab-item value="pics" key="pis" v-if="false">
              <tournament-pics></tournament-pics>
            </v-tab-item>

            <!-- AGREEMENTS -->
            <v-tab-item value="agreements" key="agreements">
              <tournament-agreement-manager :active="currentTab === 'agreements'"></tournament-agreement-manager>
            </v-tab-item>

            <!-- CHECKLIST -->
            <v-tab-item value="checklists" key="checklists" v-if="showChecklist">
              <checklist-manager :active="currentTab === 'checklists'"></checklist-manager>
            </v-tab-item>

            <!-- TEAM MAKER -->
            <v-tab-item value="team" key="team" v-if="tournament.isTournament">
              <team-maker :active="currentTab === 'team'"></team-maker>
            </v-tab-item>

            <!-- JSON PROPS -->
            <v-tab-item value="jprops" key="jprops" v-if="user && user.vbl">
              <json-props-editor :active="currentTab === 'jprops'"></json-props-editor>
            </v-tab-item>

            <!-- FIELD MANAGER -->
            <v-tab-item value="fields" key="fields" v-if="fields">
              <field-manager :active="currentTab === 'fields'"></field-manager>
            </v-tab-item>

            <!-- RATINGS RESULTS -->
            <v-tab-item value="ratings" key="ratings" v-if="tournament.hasJuniors">
              <ratings-results
                :ratingSystemId="4"
                :tourneyId="tournament.id"
              ></ratings-results>
            </v-tab-item>

          </v-tabs>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TournamentEditor from '../../components/Tournament/Edit/TournamentEditor.vue'
import QuickAddDialog from '@/components/Tournament/Registration/QuickAddDialog.vue'
import OnlineCheckinSetup from '@/components/Tournament/Edit/OnlineCheckinSetup'
import { mapGetters } from 'vuex'
const RegistrationViewer = () => import('@/components/Tournament/Registration/RegistrationViewer.vue')
const LeadViewer = () => import('@/components/Tournament/Registration/LeadViewer.vue')
const TeamImporter = () => import('@/components/Tournament/Importer/TeamImporter.vue')
const ResultsEditor = () => import('@/components/Tournament/Results/Edit/ResultsEditor.vue')
const TournamentEmailBox = () => import('@/components/Notifications/TournamentEmailBox.vue')
const BidManager = () => import('@/components/Tournament/Bids/BidManager.vue')
const PasswordProtect = () => import('@/components/Tournament/Edit/PasswordProtect.vue')
const ReportGenerator = () => import('@/components/Tournament/Reports/Index.vue')
const TournamentPics = () => import('@/components/Tournament/Photos/Index.vue')
// const TeamMaker = () => import('@/components/Team/TeamMaker.vue')
const TeamMaker = () => import('@/components/Team/TeamCombiner.vue')
const LockToggle = () => import('@/components/Utils/LockToggle.vue')
const DeleteCancelDialog = () => import('@/components/Tournament/Edit/DeleteCancelDialog.vue')
const TournamentAgreementManager = () => import('@/components/Agreements/TournamentAgreementManager.vue')
const LogoWizard = () => import('@/components/Tournament/Edit/LogoWizard.vue')
const DelayedPayment = () => import('@/components/Tournament/Edit/PreAuthWizard.vue')
const JsonPropsEditor = () => import('@/components/Tournament/JProps/JsonPropsEditor.vue')
const FieldManager = () => import('@/components/Tournament/Fields/FieldManager.vue')
const PdfPrint = () => import('@/components/QR/PdfPrint')
const DoublePoints = () => import('@/components/Tournament/Edit/DoublePoints.vue')
const ChecklistManager = () => import('@/components/Tournament/Checklist/Manager.vue')
const RatingsResults = () => import('@/components/Ratings/TournamentResults.vue')
const CustomFields = () => import('@/components/Tournament/Registration/New/CustomFields.vue')
const ScoreBoard = () => import('@/components/Scoreboards/DisplayDialog.vue')
const GameReminders = () => import('@/components/Leagues/GameReminders/DetailsDrawer')
const ClubBids = () => import('@/components/Tournament/Bids/ClubBids.vue')

export default {
  data () {
    return {
      currentTab: null,
      edit: false,
      deleteDialog: false,
      deleting: false,
      patching: false,
      loading: [],
      confirmDialog: false,
      qrLoad: false,
      qrPack: null,
      showAdmin: false,
      teamIds: null
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'getPageInfo']),
    bidPrograms () {
      const programs = []
      this.tournament.bidProgram && programs.push(this.tournament.bidProgram)
      this.tournament.bidDivisions.forEach(d => {
        const x = programs.find(p => p.id === d.bidProgram.id)
        !x && programs.push(d.bidProgram)
      })
      return programs
    },
    showEditButtons () {
      return this.$refs && this.$refs.editor
    },
    orgInfo () {
      return this.getPageInfo(this.tournament.organization.username)
    },
    showImport () {
      return this.user && ((this.orgInfo && this.orgInfo.props.includes('import')) || this.user.vbl)
    },
    showMerge () {
      return this.user && this.orgInfo && this.orgInfo.props.includes('teamMerge')
    },
    showChecklist () {
      return this.user && this.orgInfo && this.orgInfo.props.includes('checklist')
    },
    qtab () {
      return this.$route.query.tab
    },
    fields () {
      return this.tournament && this.tournament.props.includes('fields')
    },
    sbAdmin () {
      return this.user && this.user.sbAdmin
    },
    tsb () {
      return this.tournament.sanctioningBody && this.tournament.sanctioningBody.toLowerCase()
    },
    userIsSB () {
      return (this.tsb && this.sbAdmin && this.sbAdmin.includes(this.tsb)) || (this.user && this.user.vbl)
    }
  },
  methods: {
    onEmailClick (teamIds) {
      this.teamIds = teamIds
      this.currentTab = 'emailer'
    },
    toggleLock (val) {
      this.patch('locked', val)
    },
    onPublish () {
      this.patching = true
      this.loading.push('publish')
      this.$VBL.tournament.publish(this.tournament.id, !this.tournament.isPublic)
        .then((response) => {
          this.tournament.isPublic = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.patching = false
          this.loading = this.loading.filter(f => f !== 'publish')
        })
    },
    patchProps (field, value) {
      this.tournament.setProp(field, value)
      this.patch('props', this.tournament.props)
    },
    patch (field, value) {
      this.patching = true
      this.loading.push(field)
      const dto = {
        id: this.tournament.id
      }
      dto[field] = value
      this.$VBL.patch.tournament(dto)
        .catch(e => console.log(e.response))
        .finally(() => {
          this.patching = false
          this.loading = this.loading.filter(f => f !== field)
        })
    },
    deleteTournament () {
      this.deleting = true
      this.$VBL.delete.tournament(this.tournament.id)
        .then((response) => {
          this.$router.push({ name: 'organization-home', params: { username: this.tournament.organization.username } })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.deleting = false
        })
    },
    saveState (tab) {
      const query = {}
      Object.assign(query, this.$route.query)
      if (tab !== (query.tab)) {
        query.tab = tab

        this.$router.push({
          query
        })
      }
    },
    downloadQrPack () {
      this.qrLoad = true
      this.$VBL.tournament.getQrPackage(this.tournament.qrCodePackage)
        .then(r => { this.qrPack = r.data })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.qrLoad = false
        })
    }
  },
  watch: {
    currentTab: function (n, o) {
      if (n && o && o === 'settings') {
        if (this.$refs && this.$refs.editor) {
          this.$refs.editor.cancel()
        }
      }
      this.saveState(n)
    },
    qtab: function (val) {
      if (val && this.currentTab !== val) this.currentTab = val
    }
  },
  mounted () {
    if (this.$route.query.tab) {
      this.currentTab = this.$route.query.tab
    }
  },
  components: {
    ResultsEditor,
    RegistrationViewer,
    LeadViewer,
    TournamentEditor,
    QuickAddDialog,
    TournamentEmailBox,
    BidManager,
    TeamImporter,
    PasswordProtect,
    ReportGenerator,
    TournamentPics,
    TeamMaker,
    LockToggle,
    OnlineCheckinSetup,
    DeleteCancelDialog,
    TournamentAgreementManager,
    LogoWizard,
    DelayedPayment,
    JsonPropsEditor,
    FieldManager,
    PdfPrint,
    DoublePoints,
    ChecklistManager,
    RatingsResults,
    CustomFields,
    ScoreBoard,
    GameReminders,
    ClubBids
  }
}
</script>

<style>

</style>
